<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <div class="navigation-container" :style="containerStyle">
            <cms-component-button comp-id="backButton" class="navigation-part navigation-start" :component="block.backButton" v-bind="componentProps"
                                  :class="{'fill-button' : block.buttonFill}"/>

            <div class="navigation-part navigation-title">
                <cms-component-text comp-id="title" :component="block.title" v-bind="componentProps"/>
                <cms-component-text comp-id="subtitle" :component="block.subtitle" v-bind="componentProps"/>
            </div>

            <cms-component-button comp-id="nextButton" class="navigation-part navigation-end" :component="block.nextButton" v-bind="componentProps"
                                  :class="{'fill-button' : block.buttonFill}"/>
        </div>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentButton from "@/components/editor/rendering/components/cms-component-button.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-navigation #hot-reload-debug
export default {
    name: `cms-block-navigation`,
    components: {CmsComponentButton, CmsComponentText, CmsBlock},
    extends: CmsBlock,
    computed: {
        containerStyle() {
            let style = {};
            if (this.block.height > 0) {
                style[`--navigation-height`] = `${this.block.height}px`;
            }
            if (this.block.buttonWidth) {
                style[`--button-width`] = `${this.block.buttonWidth}%`;

                if (!this.block.backButton.active) {
                    style.paddingLeft = `calc(${this.block.buttonWidth}% + var(--block-h-spacing)`;
                }
                if (!this.block.nextButton.active) {
                    style.paddingRight = `calc(${this.block.buttonWidth}% + var(--block-h-spacing)`;
                }
            }
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
.navigation-container {
    --button-width: auto;
    --navigation-height: initial;

    display: flex;
    height: var(--navigation-height);
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
}

.navigation-part {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.navigation-end,
.navigation-start {
    height: 100%;
    flex-basis: var(--button-width);
    flex-grow: 1;

    ::v-deep .cms-button {
        width: 100%;
        flex: 1;
    }

    &.fill-button {
        align-items: stretch;
        justify-content: stretch;

        ::v-deep .cms-button {
            width: 100%;
            height: 100%;
            flex: 1;
        }
    }
}

.navigation-part.navigation-title {
    display: flex;
    align-items: var(--container-h-align);
    text-align: var(--container-h-align);
    padding-left: var(--block-h-spacing);
    padding-right: var(--block-h-spacing);
    flex-direction: column;
    height: 100%;
    flex: 10;
}
.navigation-end {

}
</style>
